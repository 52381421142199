import { ref } from 'vue';
import useStatefulCookie from '~/composeables/useStatefulCookie';
import {
  AlgoliaSearch,
  AlgoliaSearchPayloadRequestOptions,
  AlgoliaSearchProduct, AlgoliaSearchProductsItem,
  ProductRaw
} from '~/constants/types/algolia';
import Product from '~/models/product';

import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';
import { useGlobalContentStore } from '~/store/globalContent';
import { NorceUserTypes } from '~/constants/norceCodes';

const lastError = ref(null) as any;
const lastErrorCode = ref<number | null>(null);
const lastResponse = ref(null) as any;
const pending = ref(false);

export default function useAlgoliaFetch() {
  /**
   * Gets a single product
   * After getting the result, use Product.create(result.hits)
   * @param partNo
   */
  const getProductByPartNo = async(partNo: string): Promise<AlgoliaSearchProduct | null> => {
    return getProducts(partNo);
  };
  /**
   * Gets a single product
   * After getting the result, use Product.create(result.hits)
   * @param url
   */
  const getProductByUrl = async(url: string): Promise<AlgoliaSearchProduct | null> => {
    return getProducts(null, url);
  };

  /**
   * Gets multiple products
   * After getting the result from this, you need to use convertRawToProduct to separate the hits
   * Do not change so this is build in here - if this function is inside useAsyncData and returns product classes
   * it fails
   * @param partNos
   */
  const getProductsByPartNo = async(partNos: string[]): Promise<AlgoliaSearchProduct | null> => {
    return getProducts(partNos);
  };

  const getExactVariantByPartNo = async(partNos: string[]): Promise<AlgoliaSearchProduct | null> => {
    const filter = {
      distinct: 0,
    } as AlgoliaSearchPayloadRequestOptions;

    filter.filters = '(partNo:' + partNos.join(' OR partNo:') + ')';
    return getFromAlgolia<ProductRaw[]>(filter);
  };

  /**
   * Gets multiple products
   * After getting the result from this, you need to use convertRawToProduct to separate the hits
   * Do not change so this is build in here - if this function is inside useAsyncData and returns product classes
   * it fails
   * @param urls
   */
  const getProductsByUrl = async(urls: string[]): Promise<AlgoliaSearchProduct | null> => {
    return getProducts(null, urls);
  };

  /**
   * Gets an entire product (all variants) from a partNo or url
   */
  const getProducts = async(partNo: string | string[] | null = null, url: string | string[] | null = null): Promise<AlgoliaSearchProduct | null> => {
    const filter = {
      distinct: 0,
    } as AlgoliaSearchPayloadRequestOptions;

    if (partNo) {
      if (Array.isArray(partNo)) {
        filter.filters = '(partNumbers:' + partNo.join(' OR partNumbers:') + ')';
      } else {
        filter.filters = 'partNumbers: ' + partNo;
      }
    } else if (url) {
      if (Array.isArray(url)) {
        filter.filters = '(urls:' + url.join(' OR urls:') + ')';
      } else {
        filter.filters = 'urls:' + url;
      }
    } else {
      console.warn('getProduct called without partNo or url');
      return null;
    }

    return getFromAlgolia<ProductRaw[]>(filter);
  };

  /**
   * If hits contains more than one product, this separates them and makes them into ProductModels
   * @param hits
   * @param partNos - optional, take partNos or urs from getProducts() and insert
   * @param urls
   * @param limitedVariantLoaded - used by search
   */
  const convertRawToProducts = (
    hits: ProductRaw[],
    partNos: string[] = [],
    urls: string[] = [],
    limitedVariantLoaded = false
  ) :AlgoliaSearchProductsItem[] => {
    const productsRawArr = [] as { groupById: number, products: ProductRaw[] }[];

    hits.forEach((hit) => {
      const exists = productsRawArr.findIndex((fi) => fi.groupById === hit.groupById);
      if (exists === -1) {
        productsRawArr.push({
          groupById: hit.groupById,
          products: [ hit ],
        });
      } else {
        productsRawArr[exists].products.push(hit);
      }
    });

    return productsRawArr.map((m) => {
      const output: AlgoliaSearchProductsItem = {
        partNoRequested: '',
        urlRequested: '',
        groupById: m.groupById,
        product: Product.create(m.products, limitedVariantLoaded),
      };
      if (partNos.length) {
        const requested = output.product.partNumbers.find((f) => partNos.includes(f));
        if (requested) {
          output.partNoRequested = requested;
        }
      } else if (urls.length) {
        const requested2 = output.product.urls.find((f) => urls.includes(f));
        if (requested2) {
          output.urlRequested = requested2;
        }
      }

      return output;
    });

  };

  const getBundleProducts = async<T>(partNos: Array<string>|string) : Promise<AlgoliaSearch & {
      hits: T,
  } | null> => {
    const filter = Array.isArray(partNos) ? { filters: 'structureArticlePartNumbers:' + partNos.join(' OR structureArticlePartNumbers:') } : { filters: 'isStructureArticle: true AND structureArticlePartNumbers:' + partNos };
    return getFromAlgolia<T>(filter);
  };

  const getProductByBrandAndCategory = async<T>(brand: string, categories: Array<string>) : Promise<AlgoliaSearch & {
      hits: T,
  } | null> => {
    const filter = { filters: 'brandFilters:' + brand + ' AND categoryFilters:' + categories.join(' OR categoryFilters:') };
    return getFromAlgolia<T>(filter);
  };

  const freeTextSearch = async<T>(query: string, hitsPerPage = 24, indexSuffix = '', nonDistinct = false) : Promise<AlgoliaSearch & {
      hits: T,
  } | null> => {
    const filter = {
      query,
      hitsPerPage,
    } as AlgoliaSearchPayloadRequestOptions;
    if (nonDistinct) {
      filter.distinct = 0;
    }
    return getFromAlgolia<T>(filter, '', indexSuffix);
  };

  const getPopularProducts = async<T>(hitsPerPage = 24) : Promise<AlgoliaSearch & {
      hits: T,
  } | null> => {
    const filter = {
      hitsPerPage,
    };
    return getFromAlgolia<T>(filter);
  };

  const getFromAlgolia = async <T>(
    requestOptions: AlgoliaSearchPayloadRequestOptions,
    indexName = '',
    indexSuffix = ''
  ): Promise<(AlgoliaSearch & {
    hits: T
  }) | null> => {

    const globalContent = useGlobalContentStore();
    if (indexName === '') {
      indexName = globalContent.getAlgoliaIndex + indexSuffix;
    }

    try {
      const browserTrackingCookie = useStatefulCookie('randomId', { maxAge: 3600 * 24 * 365 });
      const userStore = useUserStore();
      if (browserTrackingCookie.value) {
        requestOptions.userToken = browserTrackingCookie.value;
      }
      requestOptions.clickAnalytics = true;
      if (!requestOptions.hitsPerPage) {
        requestOptions.hitsPerPage = 1000;
      }

      let selection = '(variantStatus:active OR variantStatus:coming OR variantStatus:expired) '; // NOTE: Keep last whitespace
      if (!userStore.loggedIn) {
        selection += 'AND isPublic:true';
        const forbidden = [] as any;
        [NorceUserTypes.Admin, NorceUserTypes.User, NorceUserTypes.Finance].map((i) => {
          forbidden.push(`NOT hideForCustomerGroups:"${i}"`);
        });

        if (forbidden.length > 0) {
          selection += ` AND (${forbidden.join(' OR ')}) `;
        }
      } else {
        const forbidden = (userStore.userProfile.company.customerGroups ?? [])
          // .filter((f) => f !== 'All')
          .map((i) => {
            return `NOT hideForCustomerGroups:"${i}"`;
          });

        if (!userStore.isSalesRepUser && !userStore.isCustomerSuccessUser && userStore.userProfile?.role?.type) {
          forbidden.push(`NOT hideForCustomerGroups:"${userStore.userProfile.role.type}"`);
        }

        if (forbidden.length > 0) {
          selection += ` AND (${forbidden.join(' AND ')}) `;
        }
      }

      if (requestOptions.filters && requestOptions.filters.trim() !== '' && selection.trim() !== '') {
        requestOptions.filters = selection + ' AND ' + requestOptions.filters;
      } else if (selection.trim() !== '') {
        requestOptions.filters = selection;
      }
      const { data, error } = await useAsyncAlgoliaSearch({
        query: '',
        requestOptions,
        key: JSON.stringify(requestOptions),
        indexName,
      });

      if (error?.value?.data) {
        console.log('ERROR');
        lastError.value = error.value.data;
        lastErrorCode.value = error.value?.statusCode || null;
        return null;
      } else {
        if (data?.value) {

          if (requestOptions.hitsPerPage > 1000 && data.value.nbHits > 1000) {
            const uiStore = useUiStore();
            console.warn('More product variants than can be loaded');
            uiStore.setTemporaryError(`Couldn\'t load all product(variant)s. ${data.value.nbHits} total of max 1000`, 0);
          }

          lastResponse.value = data.value;
          return data.value;
        }
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    } finally {
      pending.value = false;
    }
  };

  return {
    getProductByPartNo,
    getProductsByPartNo,
    getProductByUrl,
    getProductsByUrl,
    convertRawToProducts,
    getBundleProducts,
    getProductByBrandAndCategory,
    pending,
    freeTextSearch,
    getPopularProducts,
    getFromAlgolia,
    getExactVariantByPartNo,
    lastError,
    lastErrorCode,
  };
}
